<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">

    <button class="product-card__quickview" type="button" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
    </button>

    <div class="product-card__badges-list" *ngIf="product.temporada_oferta === 'S'">
        <div class="product-card__badge product-card__badge--sale">Oferta -{{ product.discount|number:'1.2-2' }}%</div>
    </div>

    <div class="product-card__image product-image">
        <a appClick [routerLink]="root.product(product)" *ngIf="product.coverImage" class="product-image__body">
            <img *ngIf="product.coverImage !== 'no-photo.png'" class="product-image__img" [src]="product.coverImage" alt="">
            <img *ngIf="product.coverImage === 'no-photo.png'" class="product-image__img" [src]="product.coverImage" alt="">
        </a>
    </div>
    <div style="width: 100%" class="text-center mb-2 d-flex justify-content-center align-items-center">
        <div class="product__actions-item">
            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" min="1" [pieces]="product.pieces_offer" [formControl]="quantity"></app-input-number>
        </div>
        <button class="btn btn-primary fab-carr" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">
            <app-icon name="cart-20" size="20"></app-icon>
        </button>
    </div>
    <div style="width: 100%" class="product-card__info">
        <div class="product-card__name">
            <a appClick [routerLink]="['/producto', product.slug]">{{ product.name }}</a>
        </div>
        <div class="display-table">
            <div *ngIf="product.stock > 0 && product.stock >= 50" class="icon-suc">
                <app-icon name="check-9x7" size="15"></app-icon>
            </div>
            <div *ngIf="product.stock > 0 && product.stock < 50" class="icon-suc-warning">
                <app-icon name="check-9x7" size="15"></app-icon>
            </div>
            <div *ngIf="product.stock < 1" class="icon-dan">
                <app-icon name="cross-20" size="15"></app-icon>
            </div>
            <div>
                <span class="item-enabled text-success font-weight-bold" *ngIf="product.stock > 0 && product.stock >= 50">Disponible</span>
                <span class="item-enabled text-warning font-weight-bold" *ngIf="product.stock > 0 && product.stock < 50">Disponible</span>
                <span class="item-enabled text-danger font-weight-bold" *ngIf="product.stock <= 0">No Disponible</span>
            </div>
            <div>
                <button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist" type="button" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                    <span class="fake-svg-icon fake-svg-icon--wishlist-16"></span>
                </button>
            </div>
        </div>
        <div class="product-card__rating ml-0">
            <div class="product-card__rating-legend ml-0 font-weight-bold" style="overflow: hidden;white-space: nowrap;">
                <span class="text-primary">SUSTANCIAS:</span> {{ product.description.split('/', 3)[0] }} <br>
                {{ product.description.split('/', 3)[1] }} {{ product.description.split('/', 3)[2]}}
            </div>
        </div>
    </div>
    <div class="product-card__actions">
        <ng-container *ngIf="product.temporada_oferta === 'S'">
            <div class="mt-2">
                A partir de:
                <span class="text-success">{{ product.pieces_offer }} Pieza<span *ngIf="product.pieces_offer > 1">s</span></span>
            </div>
            <!-- <div class="product-card__prices" style="color: #1AA552; font-size:20px">
                <ng-container>
                    <span class="product-card__new-price">{{ product.price_offer|currencyFormat }} MXN</span>{{ ' ' }}
                </ng-container>
            </div> -->
        </ng-container>
        <ng-container>
            <div class="product-card__prices" style="color: #1AA552; font-size:20px">
                <ng-container *ngIf="!product.compareAtPrice">
                    <span *ngIf="product.temporada_oferta === 'S'; else priceWithIvaShow">
                        <div class="row">
                            <div class="col-6" style="text-align: center;">
                                <span style="text-decoration-line: line-through;">
                                    {{ (product.priceWithIva | currencyFormat) }}
                                </span>
                            </div>
                            <div class="col-6" style="text-align: center;">
                                <span class="product-card__new-price">
                                    {{ (product.price_offer|currencyFormat) }}
                                </span>
                            </div>
                        </div>
                    </span>
                    <ng-template #priceWithIvaShow>
                        {{ product.priceWithIva | currencyFormat }}
                    </ng-template>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>
