<div class="search__body">
    <form [formGroup]="form" class="search__form">
        <!-- <select *ngIf="location === 'header'" class="search__categories" aria-label="Category" formControlName="category" (focus)="closeSuggestion()">
            <option value="all">Todas las Categorias</option>
            <[innerHTML]="getCategoryName(category)"
            <option
                *ngFor="let category of categories"
                [value]="category.id"
            >{{category.name}}</option>
        </select> -->
        <input class="search__input" name="search" placeholder="Buscar producto" aria-label="Site search" type="text" autocomplete="off" formControlName="query" (keydown.escape)="escape.next()" (focus)="openSuggestion()" #input>
        <button class="search__button search__button--type--submit" [routerLink]="['/search']" (click)="closeSuggestion()" [queryParams]="{term: form.controls['query'].value}">
            <app-icon name="search-20" size="20"></app-icon>
        </button>
        <button *ngIf="location === 'mobile-header'" class="search__button search__button--type--close" type="button" (click)="closeButtonClick.next()">
            <app-icon name="cross-20" size="20"></app-icon>
        </button>
        <div class="search__border"></div>
        <button class="search__button search__button--type--submit" [routerLink]="['/advanced-search']" title="Buscador Avanzado">
            <img width="20" height="20" src="assets/images/svg/advanced_search.svg">
        </button>
    </form>

    <div class="search__suggestions suggestions suggestions--location--{{ location }}">
        <div class="divisor">Sugerencias</div>
        <ul class="suggestions__list">
            <li *ngFor="let suggest of suggestedWords" class="suggestions__item suggest" (click)="onSearch(suggest)">
                {{suggest}}
            </li>
        </ul>
        <div class="divisor">Resultados ({{suggestedProductsTotal}})</div>
        <ul class="suggestions__list">
            <li *ngFor="let product of suggestedProducts" class="suggestions__item">
                <div class="suggestions__item-image product-image">
                    <div class="product-image__body">
                        <img *ngIf="product.coverImage !== 'no-photo.png'" class="product-image__img" [src]="product.coverImage" alt="">
                        <img *ngIf="product.coverImage === 'no-photo.png'" class="product-image__img" [src]="product.coverImage" alt="">
                    </div>
                </div>

                <div class="suggestions__item-info">
                    <a [routerLink]="root.product(product)" class="suggestions__item-name" (click)="closeSuggestion()">
                        {{ product.name }}
                    </a>
                    <div class="suggestions__item-meta">SKU: {{ product.sku }}</div>
                    <div class="suggestions__item-meta">Piezas en Bulto: {{ product.piezas_bulto_original }}</div>
                </div>
                <div class="suggestions__item-price d-flex align-items-center">
                    {{ (product.priceWithIva || product.price)|currencyFormat }} MXN
                    <!-- <br>
                    <ng-container *ngIf="product.temporada_oferta === 'S'">A partir de:
                        <span class="text-success">
                            <br>{{ product.pieces_offer }} Pieza<span *ngIf="product.pieces_offer > 1">s</span><br>
                        </span> <span>
                            <br><br><br> {{ (product.priceProduct || product.price)|currencyFormat }} MXN
                        </span></ng-container> -->
                </div>
                <!-- <div class="suggestions__item-price d-flex align-items-end">
                </div> -->

                <div *ngIf="location === 'header'" class="suggestions__item-actions">
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="product__actions-item product__actions-item--addtocart">
                            <button type="button" title="Agregar al carro" class="btn btn-primary btn-sm btn-svg-icon" style="height: 46px" [class.btn-loading]="addedToCartProducts.includes(product)" (click)="addToCart(product)">
                                <app-icon name="cart-16" size="16"></app-icon>
                            </button>
                        </div>
                    </div>
                    <!-- <div class="product__actions-item">
                        <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="product.pieces_offer" [formControl]="quantity"></app-input-number>
                    </div> -->
                    <!-- <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="product.pieces_offer" [formControl]="quantity"></app-input-number> -->
                </div>
            </li>
        </ul>
        <ul class="suggestions__list">
            <li class="suggestions__item suggest all_result" (click)="onSearch()">Todos los Resultados ({{suggestedProductsTotal}})</li>
        </ul>
    </div>
</div>
