import {Component, EventEmitter, Output} from '@angular/core';
import {RootService} from '../../../../shared/services/root.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {ProductService} from '../../../shop/services/products.service';

@Component({
    selector: 'app-drop-product-notification',
    templateUrl: './drop-product-notification.component.html',
    styleUrls: ['./drop-product-notification.component.scss']
})
export class DropProductNotificationComponent {

    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();
    constructor(public root: RootService,
                public notification: NotificationService,
                private _productService: ProductService) {
    }

    markReceived(notification) {
        this._productService.changeStatusNotification(notification.id).subscribe(() => {
            this.notification.load();
        });
    }
}
