import { Component, OnInit } from '@angular/core';
import { order } from '../../../../../data/account-order-details';
import { Order } from '../../../../shared/interfaces/order';
import { RootService } from '../../../../shared/services/root.service';
import { CartService } from '../../../../shared/services/cart.service';
import { OrderService } from '../../../../shared/services/order.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-order-success',
    templateUrl: './page-order-success.component.html',
    styleUrls: ['./page-order-success.component.scss']
})
export class PageOrderSuccessComponent implements OnInit {
    // order: Order = order;
    order;
    nroOrder: string;
    payMethod: string;
    sucursal: string;

    constructor(
        public root: RootService,
        public _cartService: CartService,
        public _orderService: OrderService,
        private router: Router
    ) { }

    ngOnInit() {
        this._cartService.removeAllCart();
        this.nroOrder = this._orderService.nroOrder;
        this.payMethod = this._orderService.payMethod;
        this.order = this._orderService.getOrderItems();
        this.sucursal = this._orderService.sucursal;

        if(!this.order) {
          this.router.navigate(['cuenta/dashboard']);
        }

      }

    printPage() {
      window.print();
    }
}
