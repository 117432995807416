import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform( img: string, tipo: string = 'user', id): any {


    if ( img === 'no-photo.png' ) {
      return img;
    }

    if ( img.indexOf('https') >= 0 ) {
      return img;
    }
  }

}
