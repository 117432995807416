import { ChangeDetectorRef, Component } from '@angular/core';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { Product } from '../../../../shared/interfaces/product';
import { CartService } from '../../../../shared/services/cart.service';
import { RootService } from '../../../../shared/services/root.service';
import { takeUntil, map } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { ToastrService } from 'ngx-toastr';
import { QuickviewService } from '../../../../shared/services/quickview.service';
interface Item {
    cartItem: CartItem;
    quantity: number;
    quantityControl: FormControl;
}
@Component({
    selector: 'app-wishlist',
    templateUrl: './page-wishlist.component.html',
    styleUrls: ['./page-wishlist.component.scss']
})
export class PageWishlistComponent {

    private destroy$: Subject<void> = new Subject();
    items: Item[] = [];
    quantity: FormControl = new FormControl(1);

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    beforeUrl: string;
    showingQuickview = false;

    constructor(
        public root: RootService,
        public wishlist: WishlistService,
        public cart: CartService,
        public quickview: QuickviewService,
        private toastr: ToastrService,
        private cd: ChangeDetectorRef
    ) { }

    addedToCartProducts: Product[] = [];
    removedProducts: Product[] = [];

    addToCart(product: Product | any): void {

        const quantity = this.quantity.value;

        if( product.stock < quantity ){
            this.toastr.error(`No disponible - No contamos con existencia de ${product.name}`);
            return;
        }

        if (!this.addingToCart && product && quantity > 0) {
            this.addingToCart = true;

            this.cart.add(product, quantity).subscribe({complete: () => {
                    this.addingToCart = false;
                }
            });
        }
    }

    showQuickview(product): void {
        if (this.showingQuickview) {
            return;
        }

        this.showingQuickview = true;
        this.quickview.show(product).subscribe({
            complete: () => {
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }

    remove(product: Product): void {
        if (this.removedProducts.includes(product)) {
            return;
        }

        this.removedProducts.push(product);
        this.wishlist.remove(product).subscribe({
            complete: () => {
                this.removedProducts = this.removedProducts.filter(eachProduct => eachProduct !== product);
            }
        });
    }

}
