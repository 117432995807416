<app-page-header [header]="'Favoritos'" [breadcrumbs]="[
    {label: 'Inicio',      url: '/'},
    {label: 'Favoritos', url: ''}
]"></app-page-header>

<div class="block block-empty" *ngIf="!(wishlist.items$|async).length">
    <div class="container">
        <div class="block-empty__body">
            <div class="block-empty__message">Tu lista de deseos Esta vacía!</div>
            <div class="block-empty__actions">
                <a routerLink="/" class="btn btn-primary btn-sm">Continuar</a>
            </div>
        </div>
    </div>
</div>

<div class="block" *ngIf="(wishlist.items$|async).length">
    <div class="container">
        <table class="wishlist">
            <thead class="wishlist__head">
                <tr class="wishlist__row">
                    <th class="wishlist__column wishlist__column--image">Imagen</th>
                    <th class="wishlist__column wishlist__column--product">Producto</th>
                    <th class="wishlist__column wishlist__column--stock">Estatus</th>
                    <th class="wishlist__column wishlist__column--price">Precio</th>
                    <th class="wishlist__column wishlist__column--price">Cantidad</th>
                    <!-- <th class="cart-table__column cart-table__column--quantity">Cantidad</th> -->
                    <th class="wishlist__column wishlist__column--tocart"></th>
                    <th class="wishlist__column wishlist__column--remove"></th>
                </tr>
            </thead>
            <tbody class="wishlist__body">
                <tr *ngFor="let product of wishlist.items$|async" class="wishlist__row">
                    <td class="wishlist__column wishlist__column--image">
                        <div class="product-image">
                            <a *ngIf="product.coverImage !== 'no-photo.png'" [routerLink]="root.product(product)" class="product-image__body">
                                <img class="product-image__img" [src]="product.coverImage" alt="">
                            </a>
                            <a *ngIf="product.coverImage  === 'no-photo.png'" [routerLink]="root.product(product)" class="product-image__body">
                                <img class="product-image__img" [src]="product.coverImage" alt="">
                            </a>
                        </div>
                    </td>
                    <td class="wishlist__column wishlist__column--product">
                        <a appClick (click)="showQuickview(product)" type="button" class="wishlist__product-name">{{ product.name }}</a>
                        <!-- <div class="wishlist__product-rating">
                            <app-rating [value]="product.rating"></app-rating>
                            <div class="wishlist__product-rating-legend">{{ product.reviews }} Comentarios</div>
                        </div> -->
                    </td>
                    <td class="wishlist__column wishlist__column--stock">
                        <span class="item-enabled badge badge-success font-weight-bold" *ngIf="product.stock > 0 && product.stock >= 50">Disponible</span>
                        <span class="item-enabled badge badge-warning font-weight-bold" *ngIf="product.stock > 0 && product.stock < 50">Disponible</span>
                        <span class="item-enabled badge badge-danger font-weight-bold" *ngIf="product.stock <= 0">No Disponible</span>
                        <!-- <div class="badge badge-success">Disponible</div> -->
                    </td>
                    <td class="wishlist__column wishlist__column--price">{{ product.price|currencyFormat }} MXN</td>
                    <td class="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <!-- <app-input-number [formControl]="1" [min]="1" (keyup)="update($event)" (click)="update()"></app-input-number> -->
                        <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [pieces]="product.pieces_offer" [min]="1" [formControl]="quantity"></app-input-number>
                    </td>
                    <td class="wishlist__column wishlist__column--tocart">
                        <button type="button" class="btn btn-primary btn-sm" (click)="addToCart(product)" [ngClass]="{'btn-loading': addedToCartProducts.includes(product)}">Agregar al carrito</button>
                    </td>
                    <td class="wishlist__column wishlist__column--remove">
                        <button type="button" class="btn btn-light btn-sm btn-svg-icon" (click)="remove(product)" [ngClass]="{'btn-loading': removedProducts.includes(product)}">
                        <app-icon name="cross-12" size="12"></app-icon>
                    </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
