<!-- .dropcart -->
<div class="dropcart">
    <div class="dropcart__empty" *ngIf="!(cart.items$|async).length">
        Su carrito esta vacio!
    </div>
    <ng-container *ngIf="(cart.items$|async).length">
        <div class="dropcart__totals" style="border-bottom: 1px solid #ebebeb;">
            <div class="row" style="padding-bottom: 16px;">
                <div class="col-12 pr-0">
                    <a class="btn btn-block mr-0 text-white btn-success" [routerLink]="root.cart()" (click)="closeMenu.emit()">Ver Carrito</a>
                </div>
            </div>
        </div>

        <div class="dropcart__products-list">
            <div *ngFor="let item of cart.items$|async" class="dropcart__product">
                <div class="dropcart__product-image product-image">
                    <a *ngIf="item.product.coverImage" class="product-image__body" [routerLink]="root.product(item.product)" (click)="closeMenu.emit()">
                        <img *ngIf="item.product.coverImage !== 'no-photo.png'" class="product-image__img" [src]="item.product.coverImage" alt="">
                        <img *ngIf="item.product.coverImage === 'no-photo.png'" class="product-image__img" [src]="item.product.coverImage" alt="">
                    </a>
                </div>
                <div class="dropcart__product-info">
                    <div class="dropcart__product-name">
                        <a [routerLink]="root.product(item.product)" (click)="closeMenu.emit()">{{ item.product.name }}</a>
                    </div>

                    <!-- <ul *ngIf="item.options.length" class="dropcart__product-options">
                        <li *ngFor="let option of item.options">{{ option.name }}: {{ option.value }}</li>
                    </ul> -->
                    <div class="dropcart__product-meta">
                        <span class="dropcart__product-quantity">{{ item.quantity }}</span> ×
                        <span class="dropcart__product-price">{{ item.product.price + (item.product.price * item.product.iva)|currencyFormat }} MXN</span>
                        <!-- <span class="dropcart__product-price" *ngIf="item.product.price_offer > 0 && item.product.pieces_offer > item.quantity">{{ item.product.price|currencyFormat }} MXN</span>
                        <span class="dropcart__product-price" *ngIf="item.product.price_offer > 0 && item.product.pieces_offer <= item.quantity">{{ item.product.price_offer|currencyFormat }} MXN</span> -->
                    </div>
                </div>
                <button type="button" class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" (click)="remove(item)" [ngClass]="{'btn-loading': removedItems.includes(item)}">
                    <app-icon name="cross-10" size="10"></app-icon>
                    </button>
            </div>
        </div>

        <div class="dropcart__totals">
            <table>
                <tr *ngIf="(cart.totals$|async).length">
                    <th>Subtotal</th>
                    <td>{{ cart.subtotal$|async|currencyFormat }} MXN</td>
                </tr>
                <tr *ngFor="let total of cart.totals$|async">
                    <th>{{ total.title }}</th>
                    <td>{{ total.price|currencyFormat }} MXN</td>
                </tr>
                <tr>
                    <th>Total</th>
                    <td>{{ cart.total$|async|currencyFormat }} MXN</td>
                </tr>
            </table>
        </div>

        <div class="dropcart__buttons">
            <div class="row">
                <div class="col-12 pr-0">
                    <a class="btn btn-block mr-0 text-white btn-success" [routerLink]="root.cart()" (click)="closeMenu.emit()">Ver Carrito</a>
                </div>
                <div class="col-12 mt-2 pr-0">
                    <a class="btn btn-danger btn-block text-white mr-0" (click)="emptyCar()">Vaciar Carrito</a>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<!-- .dropcart / end -->
