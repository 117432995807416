import {
    Component,
    ElementRef, EventEmitter,
    HostBinding,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit, Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { Product } from '../../interfaces/product';
import { RootService } from '../../services/root.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { count, debounceTime, map, switchMap, takeUntil, throttleTime } from 'rxjs/operators';
import { fromEvent, of, Subject } from 'rxjs';
import { ShopService } from '../../api/shop.service';
import { async } from 'rxjs/internal/scheduler/async';
import { Category } from '../../interfaces/category';
import { DOCUMENT } from '@angular/common';
import { CartService } from '../../services/cart.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

export type SearchLocation = 'header' | 'indicator' | 'mobile-header';

export type CategoryWithDepth = Category & {depth: number};

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
    exportAs: 'search',
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject<void>();
    categories: any[];
    quantity: FormControl = new FormControl(1);
    form: FormGroup;

    hasSuggestions = false;

    // categories: CategoryWithDepth[] = [];

    suggestedProducts: Product[] | any[] = [];
    suggestedProductsTotal = 0;
    suggestedWords: any[] = [];

    addedToCartProducts: Product[] = [];

    @Input() location: SearchLocation;

    @Output() escape: EventEmitter<void> = new EventEmitter<void>();

    @Output() closeButtonClick: EventEmitter<void> = new EventEmitter<void>();

    @HostBinding('class.search') classSearch = true;

    @HostBinding('class.search--location--header') get classSearchLocationHeader(): boolean { return this.location === 'header'; }

    @HostBinding('class.search--location--indicator') get classSearchLocationIndicator(): boolean { return this.location === 'indicator'; }

    @HostBinding('class.search--location--mobile-header') get classSearchLocationMobileHeader(): boolean { return this.location === 'mobile-header'; }

    @HostBinding('class.search--has-suggestions') get classSearchHasSuggestions(): boolean { return this.hasSuggestions; }

    @HostBinding('class.search--suggestions-open') classSearchSuggestionsOpen = false;

    @ViewChild('input') inputElementRef: ElementRef;

    get element(): HTMLElement { return this.elementRef.nativeElement; }

    get inputElement(): HTMLElement { return this.inputElementRef.nativeElement; }

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private fb: FormBuilder,
        private elementRef: ElementRef,
        private zone: NgZone,
        private _shopService: ShopService,
        private cart: CartService,
        public root: RootService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        // if (changes.location && this.location === 'header') {
        //     this.shop.getCategories(null, 1).pipe(
        //         takeUntil(this.destroy$),
        //     ).subscribe(categories => this.categories = this.getCategoriesWithDepth(categories));
        // }
    }

    ngOnInit(): void {
        this.getCategories();
        this.form = this.fb.group({
            category: ['all'],
            query: [''],
        });

        this.form.get('query').valueChanges.pipe(
            debounceTime(500),
            throttleTime(250, async, {leading: true, trailing: true}),
            map(query => query ? query.trim() : query),
            switchMap(query => {
                if (query) {
                    const categorySlug = this.form.value.category !== 'all' ? this.form.value.category : null;

                    return this._shopService.getSuggestions(query, 5, categorySlug, 1);
                }

                return of([]);
            }),
            takeUntil(this.destroy$),
        ).subscribe((products: any) => {
            if (products.meta && products.meta.total > 0) {
                products.data.map( product => {
                    if(product.price_offer > 0 && product.pieces_offer <= product.quantity ) {
                        return product.priceProduct = product.price_offer;
                    }else{
                        return product.priceProduct = product.price;
                    }
                });

                products.data.map( (product) => {
                    product.priceWithIva = product.price + product.price * product.iva;
                });

                this.suggestedProducts = products.data;
                this.suggestedProductsTotal = products.meta.total;

                this.hasSuggestions = true;
            } else {
                this.hasSuggestions = false;
            }
        });

        this.form.get('query').valueChanges.pipe(
            debounceTime(500),
            throttleTime(250, async, {leading: true, trailing: true}),
            map(query => query ? query.trim() : query),
            switchMap(query => {
                if (query) {
                    return this._shopService.getProductsSuggestions(query, 5, 1);
                }

                return of([]);
            }),
            takeUntil(this.destroy$),
        ).subscribe((suggestions: any) => {
            this.suggestedWords = suggestions;
        });

        this.zone.runOutsideAngular(() => {
            fromEvent(this.document, 'click').pipe(
                takeUntil(this.destroy$),
            ).subscribe(event => {
                const activeElement = this.document.activeElement;

                // If the inner element still has focus, ignore the click.
                if (activeElement && activeElement.closest('.search') === this.element) {
                    return;
                }

                // Close suggestion if click performed outside of component.
                if (event.target instanceof HTMLElement && this.element !== event.target.closest('.search')) {
                    this.zone.run(() => this.closeSuggestion());
                }
            });

            fromEvent(this.element, 'focusout').pipe(
                debounceTime(10),
                takeUntil(this.destroy$),
            ).subscribe(() => {
                if (this.document.activeElement === this.document.body) {
                    return;
                }

                // Close suggestions if the focus received an external element.
                if (this.document.activeElement && this.document.activeElement.closest('.search') !== this.element) {
                    this.zone.run(() => this.closeSuggestion());
                }
            });
        });
    }

    onSearch(term?) {
        this.hasSuggestions = false;
        this.router.navigate(['/search'], { queryParams: { term: !term ? this.form.get('query').value.trim() : term } });
        this.form.reset();
    }

    async getCategories() {
        this._shopService.getCategories().subscribe(resp => {
            this.categories = resp
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        this.closeSuggestion();
    }

    openSuggestion(): void {
        this.classSearchSuggestionsOpen = true;
    }

    closeSuggestion(): void {
        this.classSearchSuggestionsOpen = false;
        this.form.reset();
    }

    getCategoryName(category: CategoryWithDepth): string {
        return '&nbsp;'.repeat(category.depth * 4) + category.name;
    }

    addToCart(product: Product | any): void {

        if( product.stock < this.quantity.value ){
            this.toastr.error(`No disponible - No contamos con existencia de ${product.name}`);
            return;
        }

        // if(product.pieces_offer > this.quantity.value ) {
        //     this.toastr.error(`No puede comprar menos de ${product.pieces_offer} piezas para este producto`);
        //     return;
        // }

        if (this.addedToCartProducts.includes(product)) {
            return;
        }

        this.addedToCartProducts.push(product);
        this.cart.add(product, this.quantity.value).subscribe({
            complete: () => {
                this.addedToCartProducts = this.addedToCartProducts.filter(eachProduct => eachProduct !== product);
            }
        });
    }

    private getCategoriesWithDepth(categories: Category[], depth = 0): CategoryWithDepth[] {
        return categories.reduce<CategoryWithDepth[]>((acc, category) => [
            ...acc,
            {...category, depth},
            ...this.getCategoriesWithDepth(category.children || [], depth + 1),
        ], []);
    }
}
