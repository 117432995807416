import {Component, Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DesignService} from 'src/app/shared/services/design.service';
import {DirectionService} from '../../../shared/services/direction.service';
import {CompanyService} from 'src/app/shared/services/company.service';
import {Router} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {FilterService} from '../../../shared/services/filter.service';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent {
    @Input() withDepartments = false;
    banners = [];

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots: false,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 3
            }
        },
        nav: false,
        rtl: this.direction.isRTL()
    }

    options = {
        nav: false,
        dots: true,
        loop: true,
        autoplay: true,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    };

    slides = [
        // {
        //     // title: 'AHORRA',
        //     // subtitle: 'Hasta un 40% en combustible',
        //     // text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
        //     image_classic: 'assets/brand/img_slide.png',
        //     image_full: 'assets/brand/img_slide.png',
        //     image_mobile: 'assets/brand/img_slide.png'
        // },
        // {
        //     // title: 'AHORRA',
        //     // subtitle: 'Hasta un 40% en combustible',
        //     // text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
        //     image_classic: 'assets/brand/img_slide.png',
        //     image_full: 'assets/brand/img_slide.png',
        //     image_mobile: 'assets/brand/img_slide.png'
        // },
        // {
        //     // title: 'AHORRA',
        //     // subtitle: 'Hasta un 40% en combustible',
        //     // text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
        //     image_classic: 'assets/brand/img_slide.png',
        //     image_full: 'assets/brand/img_slide.png',
        //     image_mobile: 'assets/brand/img_slide.png'
        // }
    ];

    carousels = [];

    constructor(public sanitizer: DomSanitizer,
                private direction: DirectionService,
                private _designService: DesignService,
                public _companyService: CompanyService,
                public filterService: FilterService,
                private router: Router) {
        this._designService.getBanners().subscribe((banners: any[]) => {
            banners.map((banner) => {
                const slide = {
                    id: banner.id,
                    image_classic: banner.image,
                    image_full: banner.image,
                    image_mobile: banner.image,
                    type: banner.type,
                    url: banner.url
                }
                this.slides.push(slide);
            })
        });

        const level = this._companyService.user.discount;
        this._designService.getBannersList(level).subscribe((banners: any[]) => {
            this.banners = banners;
        });

        this._designService.getCarouselList().subscribe((labs: any) => {
            labs.carousels.map((lab) => {
                const labProduct = {
                    id: lab.id,
                    name: lab.name,
                    name_short: lab.name_short,
                    logo: lab.logo,
                    image: lab.image,
                    products: lab.products.map((product) => {
                        return {
                            id: product.product.id,
                            slug: product.product.slug,
                            image: product.product.cover_image
                        }
                    })
                }
                this.carousels.push(labProduct);
            })
        });
    }

    test(id) {
        this.filterService.setLabsFromCarousel(id);
        this.router.navigate(['/advanced-search']).then();
    }

    gotToUrl(id, url, type: string) {
        this._designService.incrementCountBanner(id).subscribe();
        if (type === 'Interior') {
            this.router.navigateByUrl(url);
        } else {
            window.location.href = url;
        }
    }

    incrementSlider(id) {
        this._designService.incrementCountSlider(id).subscribe();
    }
}
