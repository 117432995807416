<app-page-header header="Compra Masiva" [breadcrumbs]="[]"></app-page-header>
<!-- <app-page-header [header]="'Mi Carrito'" [breadcrumbs]="[
    {label: 'Mi Carrito', url: ''},
    {label: 'Dirección de Envío', url: ''},
    {label: 'Facturación', url: ''},
    {label: 'Pago', url: ''}
]"></app-page-header> -->

<!-- <div class="block block-empty" *ngIf="!items.length">
    <div class="container">
        <div class="block-empty__body">
            <div class="block-empty__message">Su cesta está vacía!</div>
            <div class="block-empty__actions">
                <a [routerLink]="root.shop()" class="btn btn-primary btn-sm">Continuar</a>
            </div>
        </div>
    </div>
</div> -->
<!-- <div id="stepper1" class="bs-stepper" [ngClass]="{'vertical':innerWidth <= 600}"> -->
<div id="stepper1" class="bs-stepper" role="tablist">
    <div class="container">
        <div style="text-align: center;">
            <div class="bs-stepper-header tab-resp">
                <div>Pasos para realizar tu compra masiva</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="row pt-4 justify-content-center">
                    <div class="col-md-4 text-center">

                        <div class="text-center feature feature-2">
                            <a href="https://farmaterventas.com/Compra masiva.xlsx" download class="">
                                <span class="textwidget"> <i class="fas fa-download mr-1 icon-nav" style="line-height: 60px;"></i></span>
                                <h6 class="uppercase mt-4 text-success">1. DESCARGA</h6>
                            </a>
                            <p>Descarga y rellena el archivo con su inventario</p>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="text-center feature feature-2 ">
                            <label (click)="fileInput.click()" class="pointer">
                                <span class="textwidget"> <i class="fas fa-upload mr-1 icon-nav" style="line-height: 60px;"></i></span>
                                <h6 class="uppercase mt-4 text-success">2. CARGA TU ARCHIVO</h6>
                            </label>
                            <p>Seleccina tu archivo y da clic en subir tu pedido</p>
                            <input type="file" class="d-none" (change)="onFileChange($event)" accept=".xlsx" #fileInput>
                        </div>
                    </div>
                    <div class="col-md-4 text-center">
                        <div class="text-center feature feature-2 ">
                            <span (click)="addToCart()" class="textwidget pointer"> <i class="fas fa-shopping-cart mr-1 icon-nav" style="line-height: 60px;"></i></span>
                            <h6 class="uppercase mt-4 text-success">3. AGREGAR AL CARRITO</h6>
                            <p>Agrega al carrito los productos cargados </p>
                        </div>
                    </div>
                    <!-- <div class="col-md-12 text-center">
                        <div class="text-center feature feature-2 pointer">
                            <a href="https://farmaterventas.com/Compra masiva.xlsx" download class="btn btn-success">Descargar Documento Excel <i class="ml-2 fas fa-download mr-1 icon-nav"></i></a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <table class="cart__table w-100">
                    <thead class="cart-table__head" style="border-bottom: 2px solid #28a745 !important; border-collapse: collapse !important; background-color: #fff;">
                        <tr class="cart-table__row">
                            <th class="cart-table__column cart-table__column--image">Imagen</th>
                            <th class="cart-table__column cart-table__column--product">Producto</th>
                            <th class="cart-table__column cart-table__column--image">Código</th>
                            <th class="cart-table__column cart-table__column--quantity">Piezas</th>
                            <th class="cart-table__column cart-table__column--total">Total</th>
                            <!-- <th class="cart-table__column cart-table__column--remove"></th> -->
                        </tr>
                    </thead>
                    <tbody class="cart-table__body">
                        <tr *ngFor="let item of items" class="cart-table__row">
                            <td class="cart-table__column cart-table__column--image">
                                <div *ngIf="item.coverImage" class="product-image">
                                    <a [routerLink]="root.product(item)" class="product-image__body">
                                        <img *ngIf="item.coverImage !== 'no-photo.png'" class="product-image__img" [src]="item.coverImage" alt="">
                                        <img *ngIf="item.coverImage === 'no-photo.png'" class="product-image__img" [src]="item.coverImage" alt="">
                                    </a>
                                </div>
                            </td>
                            <td class="cart-table__column cart-table__column--product">
                                <a [routerLink]="root.product(item)" class="cart-table__product-name">{{ item.name }}</a>
                                <!-- <ul *ngIf="item.cartItem.options.length" class="cart-table__options">
                                    <li *ngFor="let option of item.cartItem.options">{{ option.name }}: {{ option.value }}</li>
                                </ul> -->
                            </td>
                            <td class="cart-table__column cart-table__column--price" data-title="Price">{{ item.sku }}</td>
                            <td class="cart-table__column cart-table__column--quantity" data-title="Quantity">
                                <span>{{ item.quantity }}</span>
                            </td>
                            <td class="cart-table__column cart-table__column--total" data-title="Total" *ngIf="item.price_offer === 0">{{ (item.price + (item.price * item.iva)) * item.quantity|currencyFormat }} MXN</td>
                            <td class="cart-table__column cart-table__column--total" data-title="Total" *ngIf="item.price_offer > 0 && item.pieces_offer > item.quantity">{{ (item.price + (item.price * item.iva)) * item.quantity|currencyFormat }} MXN</td>
                            <td class="cart-table__column cart-table__column--total" data-title="Total" *ngIf="item.price_offer > 0 && item.pieces_offer <= item.quantity">{{ (item.price_offer + (item.price_offer * item.iva)) * item.quantity|currencyFormat }} MXN</td>

                            <!-- <td class="cart-table__column cart-table__column--remove">
                                <button type="button" class="btn btn-light btn-sm btn-svg-icon" (click)="remove(item.cartItem)" [ngClass]="{'btn-loading': removedItems.includes(item.cartItem)}">
                                        <app-icon name="cross-12" size="12"></app-icon>
                                    </button>
                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-12">

                <!-- <button class="btn btn-block btn-primary mt-3" [disabled]="items.length < 1" (click)="addToCart()">AGREGAR AL CARRITO</button> -->
                <div class="row mt-3 font-weight-bold" *ngIf="dataSkus.length > 0">
                    <div class="col">
                        Los siguientes códigos de productos no fueron encontrados en nuestra Base de datos
                        <ul>
                            <li *ngFor="let item of dataSkus" class="text-primary">
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="row mt-3 font-weight-bold" *ngIf="dataSkus.length == 0">
                    <div class="col">
                        Todos los productos cargados fueron agregados al listado
                    </div>
                </div> -->
            </div>
        </div>

    </div>
</div>
