<div class="block order-success">
    <div class="container">
        <div class="order-success__body">
            <div class="order-success__header">
                <app-icon class="order-success__icon" name="check-100" size="100"></app-icon>
                <h1 class="order-success__title">Gracias</h1>
                <div class="order-success__subtitle">Su orden se ha recibido</div>
                <div class="order-success__actions">
                    <a routerLink="/" class="btn btn-xs btn-secondary">Volver al inicio</a>
                </div>
            </div>

            <div class="order-success__meta">
                <ul class="order-success__meta-list">
                    <li class="order-success__meta-item">
                        <span class="order-success__meta-title">Orden:</span>
                        <span class="order-success__meta-value">#{{ nroOrder }}</span>
                    </li>
                    <li class="order-success__meta-item">
                        <span class="order-success__meta-title">Fecha:</span>
                        <span class="order-success__meta-value">{{ order.date | date }}</span>
                    </li>
                    <li class="order-success__meta-item">
                        <span class="order-success__meta-title">Total:</span>
                        <span class="order-success__meta-value">{{ order.totalAmount|currencyFormat }} MXN</span>
                    </li>
                    <li class="order-success__meta-item">
                        <span class="order-success__meta-title">Metodo de Pago:</span>
                        <span class="order-success__meta-value">{{ payMethod }}</span>
                    </li>
                </ul>
            </div>

            <div class="card">
                <div class="order-list">
                    <table>
                        <thead class="order-list__header">
                            <tr>
                                <th class="order-list__column-label" colspan="2">Producto</th>
                                <th class="order-list__column-quantity">Cantidad</th>
                                <th class="order-list__column-total">Total</th>
                            </tr>
                        </thead>
                        <tbody class="order-list__products">
                            <tr *ngFor="let item of order.product">
                                <td class="order-list__column-image">
                                    <div class="product-image">
                                        <a class="product-image__body">
                                            <img *ngIf="item.product.coverImage !== 'no-photo.png'" class="product-image__img" src="{{ item.product.coverImage }}" alt="">
                                            <img *ngIf="item.product.coverImage === 'no-photo.png'" class="product-image__img" src="{{ item.product.coverImage }}" alt="">
                                        </a>
                                    </div>
                                </td>
                                <td class="order-list__column-product">
                                    <a [routerLink]="root.product(item.product)">{{ item.product.name }}</a>
                                    <div *ngIf="item.options?.length > 0" class="order-list__options">
                                        <ul class="order-list__options-list">
                                            <li *ngFor="let option of item.options" class="order-list__options-item">
                                                <span class="order-list__options-label">{{ option.label + ': ' }}</span>
                                                <span class="order-list__options-value">{{ option.value }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td class="order-list__column-quantity" data-title="Qty:">{{ item.quantity }}</td>
                                <td class="order-list__column-total">{{ (item.product.price + (item.product.price * item.product.iva)) * item.quantity|currencyFormat }} MXN</td>
                            </tr>
                        </tbody>
                        <!-- <tbody *ngIf="order.additionalLines.length > 0" class="order-list__subtotals">
                            <tr>
                                <th class="order-list__column-label" colspan="3">Subtotal</th>
                                <td class="order-list__column-total">{{ order.subtotal|currencyFormat }}</td>
                            </tr>
                            <tr *ngFor="let line of order.additionalLines">
                                <th class="order-list__column-label" colspan="3">{{ line.label }}</th>
                                <td class="order-list__column-total">{{ line.totalAmount|currencyFormat }}</td>
                            </tr>
                        </tbody> -->
                        <tfoot class="order-list__footer">
                            <tr>
                                <th class="order-list__column-label" colspan="3">Total</th>
                                <td class="order-list__column-total">{{ order.totalAmount|currencyFormat }} MXN</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            <div class="row mt-3 no-gutters mx-n2">
                <div class="col-sm-6 col-12 px-2">
                    <div class="card address-card">
                        <div class="address-card__body">
                            <div class="address-card__badge address-card__badge--muted">Direccion de envio</div>
                            <div class="address-card__row">
                                <br> Calle: {{ order.shippingDetails.street }}<br>
                                <br> No. Exterior: {{ order.shippingDetails.exterior }}<br>
                                <br> No. Interior: {{ order.shippingDetails.interior }}<br>
                                <br> Colonia: {{ order.shippingDetails.colony }}<br>
                                <br> Ciudad: {{ order.shippingDetails.city }}<br>
                                <br> Estado: {{ order.shippingDetails.state }}<br>
                                <br> Cod. Postal: {{ order.shippingDetails.postal }}<br>
                                <br> País: {{ order.shippingDetails.country }}<br>
                                <br> Sucursal: {{ sucursal }}<br>
                            </div>
                            <!-- <div class="address-card__row">
                                <div class="address-card__row-title">Telefono</div>
                                <div class="address-card__row-content">{{ order.shippingDetails.phone }}</div>
                            </div> -->
                            <!-- <div class="address-card__row">
                                <div class="address-card__row-title">Email Address</div>
                                <div class="address-card__row-content">{{ order.shippingDetails.email }}</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                    <div class="card address-card">
                        <div class="address-card__body">
                            <div class="address-card__badge address-card__badge--muted">Direccion de Facturación</div>
                            <div class="address-card__name">{{ order.billingAddress.nameBilling }}</div>
                            <div class="address-card__row">
                                <br> Razón social: {{ order.billingAddress.razon }}<br>
                                <br> Tipo: {{ order.billingAddress.type }}<br>
                                <br> RFC: {{ order.billingAddress.rfc }}<br>
                                <br> Calle: {{ order.billingAddress.street }}<br>
                                <br> No. Exterior: {{ order.billingAddress.exterior }}<br>
                                <br> No. Interior: {{ order.billingAddress.interior }}<br>
                                <br> Colonia: {{ order.billingAddress.colony }}<br>
                                <br> Ciudad: {{ order.billingAddress.city }}<br>
                                <br> Estado: {{ order.billingAddress.state }}<br>
                                <br> Cod. Postal: {{ order.billingAddress.postal }}<br>
                                <br> País: {{ order.billingAddress.country }}<br>
                            </div>
                            <!-- <div class="address-card__row">
                                <br> {{ order.billingAddress.postalBilling }}, {{ order.billingAddress.stateBilling }}<br> {{ order.billingAddress.streetBilling }}
                            </div>
                            <div class="address-card__row">
                                <div class="address-card__row-title">Telefono</div>
                                <div class="address-card__row-content">{{ order.billingAddress.phone }}</div>
                            </div> -->
                            <!-- <div class="address-card__row">
                                <div class="address-card__row-title">Email Address</div>
                                <div class="address-card__row-content">{{ order.billingAddress.email }}</div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4 button-print">
                <div class="col-md-12 text-right">
                    <button (click)="printPage()" class="btn btn-next ml-3 btn-lg">Imprimir</button>
                </div>
            </div>
        </div>
    </div>
</div>
