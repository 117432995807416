<div class="dropcart">
    <div class="dropcart__products-list">
        <div class="dropcart_title">
            <h4>Productos Notificados</h4>
        </div>
        <div *ngFor="let item of notification.productNotifies$|async" class="dropcart__product">
            <!-- <div class="dropcart__product"> -->
            <div class="dropcart__product-image product-image">
                <a [routerLink]="['/producto', item.product_slug]" class="product-image__body" *ngIf="item.coverImage">
                    <img *ngIf="item.coverImage !== 'no-photo.png'" class="product-image__img" [src]="item.coverImage" alt="">
                    <img *ngIf="item.coverImage === 'no-photo.png'" class="product-image__img" [src]="item.coverImage" alt="">
                </a>
            </div>
            <div class="dropcart__product-info">
                <div class="dropcart__product-meta">
                    <span class="dropcart__product-quantity">
                        <a [routerLink]="['/producto', item.product_slug]" style="color: black">{{ item.product }}</a>
                    </span>
                </div>
                <div class="text-success" style="cursor: pointer; text-align: right" (click)="markReceived(item)">
                    Marcar como Recibido
                </div>
            </div>
        </div>
        <div class="dropcart__buttons">
            <div class="row">
                <div class="col-12 pr-0">
                    <a class="btn btn-block mr-0 text-white btn-success" [routerLink]="root.productNotifications()" (click)="closeMenu.emit()">Ver Todas</a>
                </div>
            </div>
        </div>
    </div>
</div>
