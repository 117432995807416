import { Component, EventEmitter, Output } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { RootService } from '../../../../shared/services/root.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-header-dropcart',
    templateUrl: './dropcart.component.html',
    styleUrls: ['./dropcart.component.scss']
})
export class DropcartComponent {
    removedItems: CartItem[] = [];

    @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        public cart: CartService,
        public root: RootService,
        private toastr: ToastrService,
    ) { }

    remove(item: CartItem): void {
        if (this.removedItems.includes(item)) {
            return;
        }

        this.removedItems.push(item);
        this.cart.remove(item).subscribe({complete: () => this.removedItems = this.removedItems.filter(eachItem => eachItem !== item)});
    }

    emptyCar(): void {
        this.removedItems = [];

        Swal.fire({
            title: 'Atencion',
            text: 'Esta a punto de vaciar el carrito',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, vaciarlo'
          }).then((borrar) => {
            if (borrar.value) {
                this.cart.removeAll().subscribe({complete: () => {
                    this.removedItems = [];
                    this.toastr.success(`Su carrito esta vacio!`);
                    this.closeMenu.emit();
                }});
            }
          });


    }
}
